/* Terminal */

#terminal {
  border: 5px solid gainsboro;
  padding: 1rem;
  margin: 0;
  margin: 0;
  background-color: black;
  background-image: radial-gradient(rgba(0, 150, 0, 0.75), black 120%);
  height: 100%;
  color: white;
  font: 1rem Inconsolata, monospace;
  text-shadow: 0 0 5px #c8c8c8;
}

#terminal::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: repeating-linear-gradient(
    0deg,
    rgba(black, 0.15),
    rgba(black, 0.15) 1px,
    transparent 1px,
    transparent 2px
  );
}
#terminal::selection {
  background: #0080ff;
  text-shadow: none;
}
.terminal-base {
  height: 100%;
  min-height: auto;
  max-width: 100%;
}
.dMFuoo {
  height: calc(100% - 60px);
}
